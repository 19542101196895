import OnloadHandler from 'onload_handler';
OnloadHandler(function () {
    let chks = document.querySelectorAll("input[type='checkbox']");
    let submitInput = document.querySelector('.pages__download__btn input');
    let notice = document.getElementById("notice");
    if (submitInput) {
        submitInput.addEventListener('click', function (e) {
                let allChecked = true
                chks.forEach(function (i) {
                    if (!i.checked) {
                        allChecked = false;
                        return allChecked;
                    }
                });
                if (!allChecked) {
                    e.preventDefault();
                    notice.classList.add("error_explanation");
                    notice.innerHTML = "Accetta i termini per scaricare il documento";
                } else {
                    notice.classList.add("success_explanation");
                    notice.innerHTML = "Documento scaricato";
                }
            }
        );
    }
})